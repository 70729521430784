import { observer } from 'mobx-react-lite';
import React, { useContext} from 'react'
import { Context } from '../..';
import { NavLink } from 'react-router-dom';
import UserIcon from './UserIcon';
import DeliveryItemSet from './DeliveryItemSet';
import LangCitySelect from './LangCitySelect';

const TopBar = observer(({openMenu, handleOpen, handleClose, phone, logo, phoneEnabled, deliveryAddressVisibility}) => {
    const {app} = useContext(Context);

  return (
    <div className='top-bar'>
        <div className='container'>
            <div className='flex mobile-menu'> 
                <div className='flex'>
                    <NavLink to={`/${app.lang}`}>
                        {logo &&
                            <img src={logo.value} 
                                className='logo'
                                alt={logo.name} 
                                title={logo.name}
                                width={115}
                                height={42}
                            />
                        }
                    </NavLink>
                    <LangCitySelect/>
                    {!deliveryAddressVisibility ? <DeliveryItemSet/> : <></>}
                    {phoneEnabled ? <div className='top-bar-phone'>
                        {phone?.map((ph, index) =>
                            <a href={`tel:+${ph}`} key={index}>{ph}</a>  
                        )}
                    </div> : <></>}
                </div>
                <div className='top-bar-wrapper'>
                    {phoneEnabled ? <div className='phones-list'>
                        {phone?.map((ph, index) =>
                            <a href={`tel:+${ph}`} key={index}>{ph}</a>  
                        )}
                    </div> : <></>}
                    <UserIcon handleClose={handleClose}/>
                </div>
            </div>
        </div>
    </div>
  )
})

export default TopBar