import React, { useContext, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';
import MapComponent from '../complex/MapComponent';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import AppServices from '../../services/general/appServices';
import YMapsComponent from '../complex/YMapsComponent';
import { YMaps } from '@pbe/react-yandex-maps';

const AddAddressModal = observer(({show, setShow, fetchItems, deliveryZones}) => {
    const {app} = useContext(Context)
    
    const [address, setAddress] = useState();

    const handleClose = () => {
        setShow(false);
    }

    const {handleSubmit, getValues, register, formState: {errors}} = useForm();

    const [addressValue, setAddressValue] = useState('');

    const [location, setLocation] = useState();

    const dropdown = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdown, false);

    const [typingTimeout, setTypingTimeout] = useState(0);

    const [availableAddresses, setAvailableAddresses] = useState();

    const CustomSubmit = async () => {
        const {entrance, floor, apartment, code} = getValues();
        if(app.addressFieldsIsRequired){
            if(!entrance || !floor || !apartment || !code ){
                toast.error(app.localizationsItems?.required_fields)
                return
            }
        }        
        const newData = {
            json_address: {
                entrance: entrance,
                floor: floor,
                apartment: apartment,
                code: code,
                address: addressValue,
                lat: address?.lat,
                lng: address?.lng
            },
            full_address: addressValue
        }

        const res = await UserServices.addresses.store(newData);

        if (res.statusCode === 200) {
            fetchItems();
            toast.success(app.localizationsItems?.success_add_address);
            setShow(false);
        }else{
            toast.error(res.message)
        }
    };


    const handleChange = (e) => {
        setIsActive(true);
        setAddressValue(e.target.value);

        clearTimeout(typingTimeout);
        if(app.yandexGeoApiKey.params?.key?.value){
            setTypingTimeout(setTimeout(async () => {
                const fullQuery = `${e.target.value},+${app.city ? app.city.label : ''}`

                let str =  fullQuery.replace(' ', '+');
                const apiKey = app.yandexGeoApiKey.params?.key?.value;
                const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&geocode=${encodeURIComponent(str)}&format=json`;

                try {
                    const response = await fetch(url);
                    const data = await response.json();
                if (data.response && data.response.GeoObjectCollection.featureMember.length > 0) {
                    const found = data.response.GeoObjectCollection.featureMember.map((item) => ({
                        name: item.GeoObject.name,
                        description: item.GeoObject.description,
                        coordinates: item.GeoObject.Point.pos.split(' '),
                    }));
                    setIsActive(true)
                    setAvailableAddresses(found);
                } else {
                    toast.error('Адрес не найден');
                    setAvailableAddresses([]);
                }
                } catch (error) {
                console.error('Ошибка поиска:', error);
                toast.error('Произошла ошибка при запросе');
                }

            }, 700));
        }
    }

    const handleSuggestionClick = async (el) => {
        setIsActive(false);
        setAddressValue(el.name);
        setLocation([el?.coordinates[1], el?.coordinates[0]])
    }
    
  return (
    <Modal show={show} onHide={handleClose} centered className={app.deliveryMapVisibility ? 'address-modal no-map' : 'address-modal'}>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className='address-modal-wrapper'>
                <div className='address-modal-container'>
                    <h3>{app.localizationsItems?.new_address}</h3>
                    <div>
                        <div className='form-label'>
                            <label>{app.localizationsItems?.address}</label>
                            <div className='search-values-container'>
                                <input 
                                    value={addressValue} 
                                    onChange={e => handleChange(e)} 
                                    placeholder={app.localizationsItems?.address} 
                                    className="input input-center"
                                ></input>
                                {isActive && <div className='search-values-items' ref={dropdown}>
                                    {availableAddresses?.map((el, index) =>
                                        <div className='search-values-item' key={index} onClick={() => handleSuggestionClick(el)}>
                                            <div>{el?.name}</div>
                                            <div className='search-values-item-sub'>{el?.description}</div>
                                        </div>
                                    )}
                                </div>}
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.entrance}</label>
                                    <input type="text" className="input" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.floor}</label>
                                    <input type="text" className="input" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.apartment}</label>
                                    <input type="text" className="input" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.code_domofon}</label>
                                    <input type="text" className="input" {...register('code')} placeholder={app.localizationsItems?.code_domofon}/>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div onClick={CustomSubmit} className='main-btn'>{app.localizationsItems?.save}</div> 
                </div>
                {!app.deliveryMapVisibility && 
                    <div className='map-container'>
                        {app.yandexGeoApiKey?.enabled ?
                            <YMaps query={{ apikey: app.yandexGeoApiKey.params?.key?.value, load: 'geocode' }}>
                                <YMapsComponent setAddress={setAddress} deliveryZones={deliveryZones} setAddressValue={setAddressValue} locationSelect={location}/> 
                            </YMaps>
                            :
                            <MapComponent setAddress={setAddress} deliveryZones={deliveryZones} setAddressValue={setAddressValue} locationSelect={location}/>
                        }
                    </div>
                }
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default AddAddressModal