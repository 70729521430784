import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../..';
import AuthServices from '../../services/general/authServices';

const ProfileTabsLink = observer(() => {
    const {app, user} = useContext(Context);
    
    const location = useLocation();
    const navigate = useNavigate();

    const logOut = async () => {
        const res = await AuthServices.logOut();
        
        if (res.statusCode === 200) {
            user.setUser({})
            user.removeToken();
            user.setTokenChange(Math.random().toString(16));
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}`);
        }
    }

  return (
    <div className='profile-left'>
        <div className='profile-left-top'>
            <div className='title'>{user.user.name}</div>
            <p>{user.user.phone}</p>
            <div className='logout' onClick={logOut}>
                <i className='icon-exit'></i>
                {app.localizationsItems?.exit_profile}
            </div>
        </div>
        <div className="tab-links">
            <div className={(location.pathname === `/${app.lang}/user` || location.pathname === `/${app.lang}/user/dashboard`) ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/dashboard`}>
                    <i className='icon-user-thin'></i>
                    {app.localizationsItems?.my_data}
                </NavLink>
            </div>

            <div className={((location.pathname === `/${app.lang}/user/orders`) || (location.pathname === `/${app.lang}/user/orders-history`)) ? 'tab-links-item active' : 'tab-links-item'} >
                <NavLink to={`/${app.lang}/user/orders`}>
                    <i className='icon-bill'></i>
                    {app.localizationsItems?.my_orders}
                </NavLink>
            </div>
            
            <div className={location.pathname === `/${app.lang}/user/addresses` ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/addresses`}>
                    <i className='icon-pin'></i>
                    {app.localizationsItems?.all_addresses}
                </NavLink>
            </div>
            
            <div className={location.pathname === `/${app.lang}/user/notifications` ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/notifications`}>
                    <i className='icon-notification'></i>
                    {app.localizationsItems?.notifications}
                </NavLink>
            </div>
            
            {/*<div className={location.pathname === `/${app.lang}/user/referral-program` ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/referral-program`}>
                    <i className='icon-team'></i>
                    {app.localizationsItems?.invite_friend}
                </NavLink>
            </div>*/}
            
            {app.reviewEnabled ? <div className={location.pathname === `/${app.lang}/user/reviews` ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/reviews`}>
                    <i className='icon-chat'></i>
                    {app.localizationsItems?.reviews}
                </NavLink>
            </div> : <></>}
            <div className={location.pathname === `/${app.lang}/user/support` ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/support`}>
                    <i className='icon-chat-1'></i>
                    {app.localizationsItems?.support}
                </NavLink>
            </div>
            
           {/*<div className={location.pathname === `/${app.lang}/user/about_us` ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/about_us`}>
                    <i className='icon-user-thin'></i>
                    {app.localizationsItems?.about_us}
                </NavLink>
            </div>
            
            <div className={location.pathname === `/${app.lang}/user/faq` ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/faq`}>
                    <i className='icon-faq'></i>
                    {app.localizationsItems?.faq}
                </NavLink>
  </div>*/}
        </div>
    </div>
  )
})

export default ProfileTabsLink