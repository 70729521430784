import React, { useContext, useEffect, useState } from 'react'
import TopBar from '../simple/TopBar';
import TopNavigation from '../simple/TopNavigation';
import Menu from '../simple/Menu';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import NotificationTop from '../simple/NotificationTop';
import DownloadAppPopup from './DownloadAppPopup';
import GoogleTranslateWidget from './GoogleTranslateWidget';

const Header = observer(() => {
    const {app} = useContext(Context);

    const [openMenu, setOpenMenu] = useState();

    const [logo, setLogo] = useState();
    const [phone, setPhone] = useState();
    const [phoneEnabled, setPhoneEnabled] = useState();
    const [deliveryAddressVisibility, setDeliveryAddressVisibility] = useState();

    const handleOpen = () => {
        setOpenMenu(true);
        document.body.style.overflow = 'hidden';
    }

    const handleClose = () => {
        setOpenMenu(false);
        document.body.style.overflow = 'auto';
    }

    useEffect(() => {
        if(app.settings.length >= 1){
            setLogo(app.settings.find(it => it.type === "logo"));
            setPhone(app.settings.find(it => it.type === "phone")?.value);
            setPhoneEnabled(app.settings.find(it => it.type === "header-phone-visibility")?.value)
            setDeliveryAddressVisibility(app.settings.find(it => it.type === "delivery-address-visibility")?.value)
        }
    }, [app.settings])
    
  return (
    <header>
        {app.autoTranslate && <GoogleTranslateWidget/>}
        <DownloadAppPopup/>
        <NotificationTop/>
        <TopNavigation openMenu={openMenu} phone={deliveryAddressVisibility} handleClose={handleClose}/>
        <TopBar 
            openMenu={openMenu} 
            handleOpen={handleOpen} 
            handleClose={handleClose} 
            phone={phone} 
            logo={logo}
            phoneEnabled={phoneEnabled}
            deliveryAddressVisibility={deliveryAddressVisibility}
        />
        <Menu/>
        {openMenu && <div className="back-close" onClick={() => setOpenMenu(false)}/>}
    </header>
  )
})

export default Header