import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import Slider from "react-slick";

const TimeSelectModal = observer(({show, setShow, setDate}) => {
    const {app} = useContext(Context);

    const handleClose = () => { setShow(false);}

    const settings = {
        arrows: true,
        slidesToShow: 4,
        infinite: false,
        initialSlide: 0,
        fade: false, 
        responsive: [
            {
                breakpoint: 1250,
                settings: { slidesToShow: 4 },
            }
        ],
    };

    const [daysOfWeek, setDaysOfWeek] = useState();
    const [selectDay, setSelectDay] = useState();

    const getDaysOfWeek = () => {
        const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
        const monthNames = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"];
        const today = new Date();
        const days = [];

        for (let i = 0; i < 8; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            const dayOfWeek = date.getDay();
            const dayOfMonth = date.getDate();

            let dayLabel = daysOfWeek[dayOfWeek];

            if (i === 0) {
                dayLabel = 'сегодня';
            } else if (i === 1) {
                dayLabel = 'завтра';
            }else {
                dayLabel = dayLabel + ', ' + dayOfMonth + ' ' + monthNames[date.getMonth()];
            }
            days.push({ dayLabel, dayOfMonth });
        }
        setSelectDay(days[0])
        return days;
    };

    useEffect(() => {
        setDaysOfWeek(getDaysOfWeek())
    }, [])

    const [visibleError, setVisibleError] = useState();

    const [minTime, setMinTime] = useState(() => {
        const now = new Date();
        const hourLater = new Date(now);
        hourLater.setHours(now.getHours() + 1);

        return hourLater.toTimeString().slice(0, 5);
    });

    const [currentTime, setCurrentTime] = useState(() => {
        const now = new Date();
        const hourLater = new Date(now);
        hourLater.setHours(now.getHours() + 1);

        return hourLater.toTimeString().slice(0, 5);
    });
    
    const handleTimeChange = (e) => {
        const selectedTime = e.target.value;
        setCurrentTime(selectedTime);
    };

    const addDate = () => {
        const work_schedule = app.settings.find(it => it.type === "work_schedule")?.value;
    
        const now = new Date();
        const selectedDate = new Date();
        selectedDate.setDate(now.getDate() + daysOfWeek.findIndex(day => day === selectDay));
    
        const dayOfWeek = selectedDate.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
        const schedule = work_schedule.week[dayOfWeek];
    
        if (!schedule) {
            setVisibleError(true);
            return;
        }
    
        if (currentTime >= schedule.begin && currentTime <= schedule.end) {
            if (
                selectedDate > now ||
                (selectedDate.toDateString() === now.toDateString() && currentTime >= minTime)
            ) {
                const newData = {
                    time: currentTime,
                    day: selectDay?.dayLabel,
                };
                setDate(newData);
                setShow(false);
            } else {
                setVisibleError(true);
            }
        } else {
            setVisibleError(true);
        }
    };

  return (
    <Modal show={show} onHide={handleClose} centered className="time-select-modal">
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <h3>{app.localizationsItems?.select_day_time}</h3>
            <div className='time-list'>
                <Slider {...settings}>
                    {daysOfWeek?.map((card, index) =>
                        <div className={selectDay === card ? 'time-list-item active' : 'time-list-item'} key={index} onClick={() => setSelectDay(card)}>{card.dayLabel}</div>
                    )}
                </Slider>
            </div>
            <div className='input-select'>
                <label>{app.localizationsItems?.time}</label>
                <input
                    className='input'
                    type="time"
                    value={currentTime}
                    //min={currentTime} // Установка минимального значения времени
                    onChange={handleTimeChange}
                />
            </div>
            <div className='main-btn' onClick={addDate}>{app.localizationsItems?.select}</div>
            {visibleError && <div className='error-time'>{app.localizationsItems?.time_unavailable}</div>}
        </Modal.Body>
    </Modal>
  )
})

export default TimeSelectModal