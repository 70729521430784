import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import MapComponent from '../complex/MapComponent';
import PickupItem from '../simple/PickupItem';
import useFetchItems from '../../hooks/useFetchItems';
import DeliveUserItems from '../complex/DeliveUserItems';
import DeliveryUserCards from '../complex/DeliveryUserCards';
import { toast } from 'react-toastify';
import MapComponentPickups from '../complex/MapComponentPickups';
import Utils from '../../services/utils';
import YMapsComponent from '../complex/YMapsComponent';
import { YMaps } from '@pbe/react-yandex-maps';
import YMapComponentPickups from '../complex/YMapComponentPickups';

const DeliverySelectModal = observer(({show, setShow}) => {
    const { app, user } = useContext(Context);
    const methods = useForm();

    const [activeItem, setActiveItem] = useState();
    const [pickupItem, setPickupItem] = useState();
    const [deliveryZones, setDeliveryZones] = useState();
    const [address, setAddress] = useState();
    const [activeId, setActiveId] = useState();
    const [addressValue, setAddressValue] = useState('');
    const [location, setLocation] = useState();

    const { items: deliveries, loading: deliveriesLoading } = useFetchItems(UserServices.cart.order.deliveries);
    const { items: pickupAddresses, fetchItems: fetchPickupAddresses } = useFetchItems(UserServices.cart.order.pickupAddresses);

    useEffect(() => {
        if(deliveries?.length >= 1){
            const deliveryItem = deliveries[0];
            if(deliveryItem){
                setActiveItem(deliveryItem.slug);
                methods.setValue('delivery_id', deliveryItem.id);
                setActiveId(deliveryItem.id);
            }
        }
    }, [deliveries])

    const handleClose = () => setShow(false);

    const handleActiveItem = (name, id) => {
        setActiveItem(name)
        setActiveId(id)
    }

    useEffect(() => {
        if(activeId){
            fetchDeliveryZones();
        }
    }, [activeId])

    const fetchDeliveryZones = async () => {
        const res = await UserServices.cart.order.deliveryZones();
        if (res.statusCode === 200) {
            setDeliveryZones(res.content.items);
        }else {
            toast.error(res.message);
        }
    };

    const validateDeliveryZone = (addressObj) => {
        let result = null;
        deliveryZones.forEach(zone => {
            if (Utils.isMarkerInsidePolygon(
                [addressObj?.lat, addressObj?.lng],
                zone.zones
            )) {
                result = zone.title;
            }
        });

        if (deliveryZones.length > 1 && !result) {
            toast.error(app.localizationsItems?.address_no_zone);
            return false;
        }

        return true;
    };

    const CustomSubmit = async () => {
        const addressId = methods.getValues('address_id');
        let newData = {}

        if (activeItem === 'self') {
            newData = addressId ? JSON.parse(addressId) : {
                pickup_id: pickupItem?.id,
                full_address: pickupItem?.title,
                location: pickupItem?.location
            };
        }else{
            if(user.token){
                const tempAddress = addressId ? JSON.parse(addressId) : null;
                if (!validateDeliveryZone(tempAddress?.json_address)) return;
                newData = tempAddress;
            }else{
                const {entrance, floor, apartment, code} = methods.getValues();
                if(app.addressFieldsIsRequired){
                    if(!entrance || !floor || !apartment || !code ){
                        toast.error(app.localizationsItems?.required_fields)
                        return
                    }
                }
                if (!validateDeliveryZone(address)) return;
                newData = {
                    json_address: {
                        entrance: entrance,
                        floor: floor,
                        apartment: apartment,
                        code: code,
                        address: addressValue,
                        lat: address?.lat,
                        lng: address?.lng
                    },
                    full_address: addressValue
                };
            }
        }

        newData = { ...newData, delivery_id: methods.getValues('delivery_id') };

        user.setAddress(newData);
        setShow(false);
    };


  return (
    <Modal show={show} onHide={handleClose} centered className={app.deliveryMapVisibility ? 'address-modal no-map' : 'address-modal'}>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <FormProvider {...methods}>
                <div className='address-modal-wrapper'>
                    <div className={deliveriesLoading ? 'address-modal-container isLoading' : 'address-modal-container'}>
                        <div className='address-modal-top'>
                            {deliveries?.map((el, index) =>
                                <div className={activeItem === el.slug ? 'address-modal-top-item active' : 'address-modal-top-item'} onClick={() => handleActiveItem(el.slug, el.id)} key={index}>
                                    <input className="delivery-check-input" required value={el.id} type="radio" 
                                        {...methods.register('delivery_id', {required: true})}
                                    />
                                    {el.name}
                                </div>
                            )}
                        </div>
                        {activeItem === "self" ?
                            <>
                                <h3 className='mob-none'>{app.localizationsItems?.select_pickup}</h3>
                                <PickupItem pickupAddresses={pickupAddresses} setPickupItem={setPickupItem} pickupItem={pickupItem}/>
                                <div onClick={CustomSubmit} className='main-btn'>{app.localizationsItems?.save}</div> 
                            </>
                            :
                                user.token ?
                                    <DeliveryUserCards address={address} 
                                        CustomSubmit={CustomSubmit} 
                                        addressValue={addressValue} 
                                        setAddressValue={setAddressValue}
                                        deliveryZones={deliveryZones}
                                        setLocation={setLocation}
                                    />
                                    :
                                    <DeliveUserItems
                                        CustomSubmit={CustomSubmit} 
                                        addressValue={addressValue} 
                                        setAddressValue={setAddressValue}
                                        setAddress={setAddress}
                                        setLocation={setLocation}
                                    />
                        }
                    </div>
                    {!app.deliveryMapVisibility &&
                        <>
                            {activeItem === "self" ?
                                <div className='map-container'>
                                    {app.yandexGeoApiKey?.enabled ?
                                        <YMaps query={{ apikey: app.yandexGeoApiKey.params?.key?.value, load: 'geocode' }}>
                                            <YMapComponentPickups setAddress={setAddress} pickupAddresses={pickupAddresses} pickupItem={pickupItem} setPickupItem={setPickupItem}/> 
                                        </YMaps>
                                        :
                                        <MapComponentPickups setAddress={setAddress} pickupAddresses={pickupAddresses} pickupItem={pickupItem} setPickupItem={setPickupItem}/>
                                    }
                                </div>
                                :
                                <div className='map-container'>
                                    {app.yandexGeoApiKey?.enabled ?
                                        <YMaps query={{ apikey: app.yandexGeoApiKey.params?.key?.value, load: 'geocode' }}>
                                            <YMapsComponent setAddress={setAddress} deliveryZones={deliveryZones} setAddressValue={setAddressValue} locationSelect={location}/> 
                                        </YMaps>
                                        :
                                        <MapComponent setAddress={setAddress} deliveryZones={deliveryZones} setAddressValue={setAddressValue} locationSelect={location}/>
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
            </FormProvider>
        </Modal.Body>
    </Modal>
  )
})

export default DeliverySelectModal